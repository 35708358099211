import { Tabs, TabList, TabPanels, Tab, TabPanel, Text, FormLabel, FormControl, Input, VStack } from "@chakra-ui/react";
import { InquiryViewTab } from "./tab/InquiryViewTab";
import { PostNoticeTab } from "./tab/PostNoticeTab";
import { useContext } from "react";
import { ThemeContext } from "./constants";
import { useAppDispatch, useAppSelector } from "./redux";
import { selectApiKey, setApiKey } from "./redux/apiKeySlice";

export const HomePage = () => {
  const theme = useContext(ThemeContext);
  const apiKey = useAppSelector(selectApiKey);
  const dispatch = useAppDispatch();

  const handleTextArea = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault(); dispatch(setApiKey(e.target.value));
  };

  return (
    <VStack w="80%">
      <FormControl>
        <FormLabel>관리자 코드</FormLabel>
        <Input type="text" value={apiKey} onChange={handleTextArea} />
      </FormControl>
      <Tabs w={"100%"}>
        <TabList>
          <Tab>
            <Text fontSize={theme.largeFontSize}>공지 작성</Text>
          </Tab>
          <Tab >
            <Text fontSize={theme.largeFontSize}>건의 확인</Text>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel><PostNoticeTab /></TabPanel>
          <TabPanel><InquiryViewTab /></TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};
