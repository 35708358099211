import { VStack } from "@chakra-ui/react";
import { HomePage } from "./HomePage";
import { Header } from "./component/Header";
import { Provider } from "react-redux";
import { persistor, store } from "./redux";
import { ThemeContext, themes } from "./constants";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { PersistGate } from "redux-persist/integration/react";

export const App = () => {
  const [theme, setTheme] = useState(themes.largeDevice);

  const isPc = useMediaQuery({
    query: "(min-width:1024px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width:768px) and (max-width:1023px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });

  useEffect(() => {
    if (isPc) {
      setTheme(themes.largeDevice);
    } else if (isTablet) {
      setTheme(themes.largeDevice);
    } else if (isMobile) {
      setTheme(themes.mobile);
    }
  }, [isPc, isTablet, isMobile]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeContext.Provider value={theme}>
          <VStack className="app" w="100%">
            <Header />
            <HomePage />
          </VStack>
        </ThemeContext.Provider>
      </PersistGate>
    </Provider>
  );
};
