import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

interface ApiKeyState {
  key: string;
}

const initialState: ApiKeyState= {
  key: "",
};

export const apiKeySlice= createSlice({
  name: "apiKey",
  initialState,
  reducers: {
    setApiKey: (state, userData: PayloadAction<string >) => {
      state.key= userData.payload;
    },
  },
});

//selectors
export const selectApiKey= (state: RootState): string=> {
  return state.apiKey.key; 
};

// actions
export const {setApiKey} = apiKeySlice.actions;

export const apiKeyReducer = apiKeySlice.reducer;
