import React, { useContext } from "react";
import { Text } from "@chakra-ui/react";

import { bgHeaderColor, ThemeContext } from "../constants";

export const Header: React.FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <Text bgColor={bgHeaderColor} w="100%" fontSize={theme.mediumFontSize} p="1rem">
      소통앱 관리자 페이지
    </Text>
  );
};
