import React, { useEffect } from "react";
import { Button, List, ListItem } from "@chakra-ui/react";
import { InquiryView } from "../component/InquiryView";
import { appApi } from "../redux/appApi";
import { useAppSelector } from "../redux";
import { selectApiKey } from "../redux/apiKeySlice";
import { useMakeToast } from "../function/makeToast";

export const InquiryViewTab: React.FC = () => {
  const rawApiKey = useAppSelector(selectApiKey)
  const [trigger, result] = appApi.useLazyGetInquirySummariesQuery()
  const { isSuccess, isError, data: inquiries } = result;
  const makeToast = useMakeToast();

  useEffect(() => {
    if (isSuccess) {
      makeToast({ isSuccess: true, description: `건의 불러오기를 성공했습니다` });
    } else if (isError) {
      makeToast({ isSuccess: false, description: "건의 불러오기를 실패했습니다" });
    }
  }, [isSuccess, isError])

  return (
    <>
      <Button onClick={async () => { trigger({ rawApiKey, start: 0, length: 10 }) }}>데이터 새로고침</Button>
      <List>
        {inquiries?.map((val) => {
          return (
            <ListItem key={val.id} m="1rem" shadow={"md"} rounded="2xl">
              <InquiryView
                inquiry={val}
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
