import { createContext } from "react";

export const SERVER_URL = "https://sotong-forum.sshs-pebble.dev";
export const shadowColor = "rgba(0, 0, 0, 0.15)";
export const bgLightgray = "gray.50";
export const bgHeaderColor = "gray.200";
export const bgLightblue = "blue.100";

export const themes = {
  largeDevice: {
    smallFontSize: "sm",
    mediumFontSize: "md",
    largeFontSize: "lg",
    boldFontWeight: "700",
    deviceType: "large",
  },
  mobile: {
    smallFontSize: "xs",
    mediumFontSize: "sm",
    largeFontSize: "md",
    boldFontWeight: "700",
    deviceType: "mobile",
  },
};
export const ThemeContext = createContext(themes.largeDevice);
