import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { bgLightgray } from "../constants";
import { Post } from "../types";
import { useMakeToast } from "../function/makeToast";
import { appApi } from "../redux/appApi";
import { useAppSelector } from "../redux";
import { selectApiKey } from "../redux/apiKeySlice";

const defaultFormData: Post = {
  title: "",
  authorId: "",
  boardName: "",
  bodyPreview: "",
  body: ""
};

export const PostNoticeTab: React.FC<{}> = () => {
  const apiKey = useAppSelector(selectApiKey);
  const [formData, setFormData] = useState<Post>(defaultFormData);
  const [postNotice, status] = appApi.usePostNoticeMutation();
  const makeToast = useMakeToast();

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearFormData = () => {
    setFormData(defaultFormData);
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    postNotice({ post: { ...formData, boardName: 'notice' }, rawApiKey: apiKey })
    clearFormData();
  };

  useEffect(() => {
    if (status.isSuccess) {
      makeToast({ isSuccess: true, description: `공지가 전송되었습니다` });
    } else if (status.isError) {
      makeToast({ isSuccess: false, description: "공지 전송을 실패했습니다" });
    }
  }, [status])

  return (
    <Box bgColor={bgLightgray} shadow="md" w="100%">
      <form onSubmit={onSubmit}>
        <Button type="submit" m="1rem" w="calc(100% - 2rem)">
          등록
        </Button>

        <FormControl isInvalid={formData.title === ""} p="1rem">
          <FormLabel>제목</FormLabel>
          <Input type="text" name="title" value={formData.title} onChange={onInputChange} />
          <FormErrorMessage>제목을 입력해주세요</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formData.authorId === ""} p="1rem">
          <FormLabel>작성자</FormLabel>
          <Input type="text" name="authorId" value={formData.authorId} onChange={onInputChange} />
          <FormErrorMessage>작성자를 입력해주세요</FormErrorMessage>
        </FormControl>
        {/* <FormControl isInvalid={formData.boardName === ""} p="1rem">
          <FormLabel>게시판</FormLabel>
          <Input type="text" name="boardName" value={formData.boardName} onChange={onInputChange} />
          <FormErrorMessage>게시판을 입력해주세요</FormErrorMessage>
        </FormControl> */}

        <FormControl isInvalid={formData.bodyPreview === ""} p="1rem">
          <FormLabel>내용 요약</FormLabel>
          <Textarea name="bodyPreview" value={formData.bodyPreview} onChange={onInputChange} />
          <FormErrorMessage>내용 요약을 입력해주세요</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formData.body === ""} p="1rem">
          <FormLabel>내용</FormLabel>
          <Textarea name="body" value={formData.body} onChange={onInputChange} />
          <FormErrorMessage>내용을 입력해주세요</FormErrorMessage>
        </FormControl>
      </form>
    </Box>
  );
};
