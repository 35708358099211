import React, { useState } from "react";
import { Box, Center, Flex, HStack, Text } from "@chakra-ui/react";
import { bgLightblue, bgLightgray } from "../constants";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { appApi } from "../redux/appApi";
import { ShortPost } from "../types";
import { useAppSelector } from "../redux";
import { selectApiKey } from "../redux/apiKeySlice";
import { formatTime } from "../function/formatTime";

const InquiryBodyView: React.FC<{ id: string }> = ({ id }) => {
  const rawApiKey = useAppSelector(selectApiKey)
  const { data } = appApi.useGetInquiryQuery({ id, rawApiKey });
  return <Flex p="5" flexDir={"column"} gap="5">
    <Text>{data?.body}</Text>
  </Flex>
}

export const InquiryView: React.FC<{
  inquiry: ShortPost;
}> = ({ inquiry }) => {
  const [isShowDetail, setIsShowDetail] = useState(false);

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsShowDetail((isShowDetail) => !isShowDetail);
  };

  return (
    <Box bgColor={bgLightgray} rounded="2xl">
      <Flex
        minH={"4rem"}
        align="center"
        onClick={onClick}
        w="100%"
        justifyContent="space-between"
        bgColor={bgLightblue}
        roundedTop="2xl"
      >
        <HStack gap="0.2rem">
          <Text marginStart={"1rem"} fontSize="larger" fontWeight="700">
            {inquiry.title}
          </Text>
          <Text fontSize="sm">작성자: {inquiry.authorId} </Text>
          <Text fontSize="sm">작성일시: {formatTime(new Date(inquiry.createdAt))}</Text>
        </HStack>
        <Center marginEnd={"2rem"}>
          <ChevronDownIcon w="10" h="10" />
        </Center>
      </Flex>

      {isShowDetail && (<InquiryBodyView id={inquiry.id} />)}
    </Box>
  )
};