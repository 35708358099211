import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import { SERVER_URL } from "../constants";
import { hash } from "../function/hash";
import { Post, PostSave, ShortPost } from "../types";

export const appApi = createApi({
  reducerPath: "agendaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVER_URL}/`,
    prepareHeaders: (headers) => {
      return headers;
    },
    credentials: "include",
  }),
  endpoints: (builder) => ({
    postNotice: builder.mutation<void, { post: Post; rawApiKey: string }>({
      queryFn: async ({ post, rawApiKey }) => {
        const res = await axios.post(`${SERVER_URL}/post`, post, {
          headers: { "x-api-key": hash(rawApiKey) },
          withCredentials: true,
        });
        return { data: res.data };
      },
    }),

    getInquirySummaries: builder.query<
      ShortPost[],
      { start: number; length: number; rawApiKey: string }
    >({
      queryFn: async ({ start, length, rawApiKey }) => {
        const res = await axios.get(
          `${SERVER_URL}/board/inquiry/shortpost?start=${start}&length=${length}`,
          {
            headers: { "x-api-key": hash(rawApiKey) },
            withCredentials: true,
          }
        );
        return { data: res.data };
      },
    }),
    getInquiry: builder.query<PostSave, { id: string; rawApiKey: string }>({
      queryFn: async ({ id, rawApiKey }) => {
        const res = await axios.get(`${SERVER_URL}/post/${id}`, {
          headers: { "x-api-key": hash(rawApiKey) },
          withCredentials: true,
        });
        return { data: res.data };
      },
    }),
  }),
});
